<template>
  <div style="width: 100%; height: 100%; overflow: auto">
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
import { getCats } from "@/service/item";
import { fetchTree } from "@/util";
import SubMenu from "@/views/layout/SubMenu";
export default {
  components: {
    // SubMenu
  },
  data() {
    return {
      query: {
        key: null,
        type: null,
      },
      cats: [],
      catId: null,
    };
  },
  computed: {
    routePath() {
      return this.$route.path;
    },
  },
  mounted() {},
  created() {
    getCats().then((rst) => {
      this.cats = fetchTree(rst, null, {
        name: { dest: "title" },
        id: { dest: "key" },
        parent: "parentId",
      });
      console.log(this.cats);
    });
    if (this.$route.query.search) {
      this.query.key = this.$route.query.search;
    }
  },
  methods: {
    goto(path) {
      this.$router.push(path);
    },
    search() {
      this.$router.push("/sale/mall/home/factory?search=" + this.query.key);
    },
    goCat(item) {
      // console.log(item);
      this.catId = item.key;
      this.$router.push("/sale/mall/home/factory?catId=" + item.key);
    },
    gotoBank(path) {
      window.open(path);
    },
  },
};
</script>
<style scoped>
.search-bar {
  min-width: 1200px;
  background: #fff;
  padding: 30px 0;
  border-bottom: 2px solid #eee;
}
.search-bar > div {
  width: 1200px;
  margin: 0 auto;
}
.search-bar > div >>> .el-select .el-input {
  width: 130px;
}
div.menu >>> .ant-menu-vertical .ant-menu-submenu {
  overflow-y: auto;
  height: 300px;
}
div.menu >>> .ant-menu-horizontal > .ant-menu-item {
  height: 60px;
  line-height: 60px;
  font-size: 16px;
}
</style>
<style lang="less" scoped>
.container {
  width: 1200px;
  margin: 0 auto;
}
.cat-item {
  width: 200px;
  height: 60px;
  background-color: #fff;
  color: #292929;
  line-height: 60px;
  text-align: center;
  position: relative;
  font-weight: bold;
  background: rgba(240, 243, 255, 1);
  cursor: pointer;
  font-size: 16px;
  &.active {
    display: none;
  }
  &:hover {
    .cat-menu {
      display: block;
    }
  }
  .cat-menu {
    display: none;
    position: absolute;
    width: 200px;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(169, 182, 225, 1);
    line-height: 1;
    font-size: 12px;
    z-index: 100;
    .cat-menu-item {
      padding: 10px 20px;
      text-align: left;
      position: relative;
      &:hover {
        background: rgba(204, 204, 204, 0.2);
        .cat-sub-menu {
          font-weight: 400;
          display: block;
        }
      }
      h3 {
        color: #292929;
        margin-bottom: 12px;
        font-weight: bold;
        font-size: 14px;
        &.active {
          color: #5074ee;
        }
      }
      > .item-list {
        color: #4d4d4d;
        span {
          margin-right: 10px;
          margin-bottom: 10px;
          &.active {
            color: #5074ee;
          }
          &:hover {
            color: #5074ee;
          }
        }
      }
      .cat-sub-menu {
        display: none;
        width: 306px;
        // height: 112px;
        background: #fff;
        box-shadow: 0px 2px 4px 0px rgba(169, 182, 225, 1);
        position: absolute;
        right: -306px;
        top: 0;
        color: #4d4d4d;
        padding: 20px;
        h4 {
          font-size: 14px;
          margin-bottom: 12px;
          font-weight: bold;
          &.active {
            color: #5074ee;
          }
        }
        > div {
          span {
            margin-right: 10px;
            margin-bottom: 10px;
            &.active {
              color: #5074ee;
            }
            &:hover {
              color: #5074ee;
            }
          }
        }
      }
    }
  }
}
</style>
